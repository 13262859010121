import React, { useEffect, useState } from "react"
import shape from "../components/assets/shape.png"
import shape1 from "../components/assets/shape1.png"
import Technologies from "./technologies"
import axios from 'axios'
import Email from "./email"

export default function Home() {
    const [data, setData] = useState([])

    useEffect(() => {
        gethomePost()
    }, [])

    const gethomePost = () => {
        axios.get("https://minidigitalserver.frogit.lv/posts/frogit/page/44/1")
            .then(response => setData(response.data))

    }

    return (
        <div className="column">
            <section id="sākums" className="home ">
                <div className="shape">
                    <img src={shape} alt="shape" title="vilnis"/>
                </div>
                <div className="shape1" >
                    <img src={shape1} alt="shape" title="vilnis"/>
                </div>
                <div className="heading">
                    <h1>{data[0]?.title}</h1>
                    <p>{data[0]?.text}</p>
                    <Email/>
                </div>
                <div className="hero">
                    <img title="Hero attēls" src={data[0]?.file ? "https://minidigitalserver.frogit.lv" + data[0]?.file: ""} alt="hero" width="450" height="auto" />
                </div>
            </section >
            <Technologies />
        </div>
    )
}