import React, {useState, useEffect} from "react"
import { Link } from "react-router-dom";
import axios from "axios"

export default function Recents(){
    const [data, setData] = useState([])

    useEffect(() => {
        getBlogposts()
    }, [])

    const getBlogposts = () => {
        axios.get("https://minidigitalserver.frogit.lv/posts/frogit/page/49/1")
            .then(response => setData(response.data))
    }
    const fileterd = data.toReversed().filter((value, index) => index < 3)

    return(
        <div className="recents">
        <h3> Neseni ieraksti</h3>
        {fileterd.map(x =>
            <span key={x.id}>
                < img title={x.text} src={"https://minidigitalserver.frogit.lv" + x.file} alt={x.text} width="100px" />
                <Link to={`/blogs/${x.id}`} state={{ data: x }} className="link"><h3>{x.title}</h3></Link>
            </span>
        )}
    </div>
    )
}