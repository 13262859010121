import React, {useEffect, useState} from "react"
import axios from 'axios'

export default function Projects() {
    const [data, setData] = useState([])

    useEffect(() => {
        getProjects()
    }, [])

    const getProjects = () => {
        axios.get("https://minidigitalserver.frogit.lv/posts/frogit/page/46/1")
            .then(response => setData(response.data))

    }

    return (
        <section id="projekti" className="projects">
            <h3>Projekti</h3>
            <div className="row">
                {data.toReversed().map(x=> 
                <div className="proj1" key={x.id}>
                    <img title="Projekta attēls" src={"https://minidigitalserver.frogit.lv" + x.file} alt="project" width="100%" height="250px"/>
                    <a href={x.externalLink} target="blank" title={`"saite uz" + ${x.title}`}><h4>{x.title}</h4></a>
                    <p>{x.text}</p>
                </div>
                )}
                </div>
        </section>
    )
}