import React, { useEffect, useState } from "react"
import { useCookies } from 'react-cookie';

export default function Cookies() {
    const [open, setopen] = useState(false)
    const [cookies, setCookie] = useCookies(['consent']);

    useEffect(() => {
        if (cookies.consent) {
            setopen(false)
        }
        else { setopen(true) }
    },[cookies.consent])

    const consent = (e) => {
        e.preventDefault()
        setopen(false)
        setCookie("consent", true)
    }
    return (
        <>
            {open && <div className="cookies">Šī vietne izmanto sīkdatnes. <button onClick={consent}>Atļaut</button></div>}
        </>
    )
}