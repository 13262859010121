import React, {useState, useEffect} from "react"
import axios from 'axios'

export default function Offers() {
    const [data, setData] = useState([])

    useEffect(() => {
        gethomePost()
    }, [])

    const gethomePost = () => {
        axios.get("https://minidigitalserver.frogit.lv/posts/frogit/page/45/1")
            .then(response => setData(response.data))

    }

    return (
        <section id="pakalpojumi" className="offers">
            <h3>Piedāvājam</h3>
            <div className="row">
               {data.map(x=>  <div className="block" key={x.id}>
                    <h4>{x.title}</h4>
                    <p>{x.text}</p>
                </div>
                )}
            </div>
        </section>
    )
}