import React from "react"

export default function Modal({status}){
    return(
        <div className="modalback">
        <div className="modal">
            <h3>{status === "ok" ? "Jūsu ziņa veiksmīgi nosūtīta!" : "Neizdevās nosūtīt. Mēģiniet vēlreiz vēlāk."}</h3>
        </div>
        </div>
    )
}