import React from "react"
import "../App.css"
import {CiFacebook} from "react-icons/ci#CiFacebook"

export default function Topper() {
    return (
        <div className="topper">
            <p>info@frogit.lv</p>
            <span>
<a href="https://www.facebook.com/profile.php?id=61552792137826" title="saite uz Frogit facebook profilu "><CiFacebook color="white" size={25}/></a>
            </span>
        </div>
    )
}