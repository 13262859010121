import React from "react"
import { Outlet } from "react-router-dom"
import Menu from "../menu"

export default function Blog() {

    return (
        <>
            <Menu />
            <Outlet />
        </>
    )
}