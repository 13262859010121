import React, { useState } from "react"
import axios from 'axios';
import Modal from "./modal";

export default function Contacts() {
    const [status, setstatus] = useState()
    const [open, setopen] = useState(false)

    const send = (e) => {
        e.preventDefault()

        const data = {
            name: e.target[0].value,
            email: e.target[1].value,
            text: e.target[2].value,
            receiver: "info@frogit.lv"
        }
        
        axios.post('https://mail.frogit.lv/mail.php', { data: data }, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
            .then(response => setstatus(response.data.status))
            .then(modaltime)
            .catch(error => {
                modaltime()
            });
    }

    const modaltime =()=>{
        setopen(true)
        setTimeout(() => {
            // After 3 seconds set the show value to false
            setopen(false)
          }, 7000)
    }

    return (
        <section id="kontakti" className="contacts">
            <h3>Sazinies ar mums</h3>
            <form onSubmit={send}>
                <input type="text" placeholder="Vārds" />
                <input type="email" placeholder="E-pasts" required />
                <textarea placeholder="Tava ziņa" cols="40"
                    rows="5" />
                <button type="submit"><p>Nosūtīt</p></button>
            </form>
            {open && <Modal status={status}/>}
        </section>
    )
}