import React, { useState, useEffect } from "react"
import "../App.css"
import logo from "../components/assets/logo.png"
import { BiMenu } from "react-icons/bi#BiMenu"
import logoWhite from "../components/assets/baltslogo.png"
import { TfiClose } from "react-icons/tfi#TfiClose"
import ScrollToAnchor from "./scrollToAnchor"
import axios from 'axios'

export default function Menu() {

    const [openmenu, setOpenmenu] = useState(false)
    const [menu, setMenu] = useState([])

    useEffect(()=>{
        getMenu()
    }, [])

    const getMenu = () => {
        axios.get("https://minidigitalserver.frogit.lv/pages/frogit")
            .then(response => setMenu(response.data))
    }

    const openMenu = () => {
        setOpenmenu(true)
    }

    const close = (e) => {
        setOpenmenu(false)
    }

    return (
        <div className="menu onscroll">
            <ScrollToAnchor />

            <a href="/#sākums"><img src={logo} alt="logo" width="140" height="auto" title="Frogit logo"/></a>

            <ul>
                {menu.map(x => <li key={x.id}><a href={x.Info} title={`"saite uz" + ${x.Name}`}>{x.Name}</a></li>)}
            </ul>
            <div className="ec-cart-widget"></div>

            <nav onClick={openMenu}>
                <BiMenu size={30} color="white" />
            </nav>
            {openmenu && <div className="mobileMenu">
                <div className="row-menu">
                    <img src={logoWhite} alt="logo" width="150" height="auto" title="Frogit logo"/>
                    <TfiClose color="white" size={20} onClick={close} style={{ cursor: 'pointer' }} />
                </div>
                <ul onClick={close}>
                {menu.map(x => <li key={x.id} onClick={close}><a href={x.Info} title={`"saite uz" + ${x.Name}`}>{x.Name}</a></li>)}
                </ul>
            </div>}

        </div>
    )
}