import React from "react"
import "../App.css"
import image from "../components/assets/FotoValtersPelns_web-0039.jpg"
import ivars from "../components/assets/Untitled design (3).png"

export default function Team() {
    return (
        <section id="komanda" className="team">
            <h3>Komanda</h3>
            <div className="row">
                <div className="column">
                    <img src={image} alt="Sandra" title="Projektu vadītāja Sandra"/>
                    <h2>Sandra</h2>
                    <p>Projektu vadītāja, front-end programmētāja</p>
                    <div className="row">
                    </div>
                </div>

                <div className="column">
                    <img src={ivars} alt="Ivars" title="Programmētājs Ivars"/>
                    <h2>Ivars</h2>
                    <p>Back-end programmētājs</p>
                    <div className="row">
                    </div>
                </div>
            </div>
        </section>
    )
}