import React, { useRef, useEffect } from "react";
import Menu from "./menu";
import { Helmet } from 'react-helmet-async';

export default function Shop() {
    const storeDiv = useRef(null);
    const scriptRef = useRef(null);

    window.localStorage.setItem("show_ecwid_logs", "true")
    window.ecwid_script_defer = true;
    window.ecwid_dynamic_widgets = true;
    window.ec = window.ec || Object();
    window.ec.storefront = window.ec.storefront || Object();
    window.ec.enable_catalog_on_one_page = true;
    window._xnext_initialization_scripts = [{
        widgetType: 'ProductBrowser',
        id: 'my-store-105723900',
        arg: ["id=productBrowser", "views=grid(20,3)"]
    },
    {
        widgetType: 'CategoriesV2',
        id: 'my-categories-#####',
        arg: ["id=categoriesV2"]
    }
        ,
    {
        widgetType: 'SearchWidget',
        id: 'my-search-####',
        arg: ["id=searchWidget"]
    }
    ];

    var script = document.createElement('script');
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.src = 'https://app.ecwid.com/script.js?105723900';
    script.defer = true;
    script.ref = scriptRef;


    useEffect(() => {
        if (!scriptRef.current) {
            storeDiv.current.appendChild(script);
        }
    });
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Frogit.lv veikals</title>
                <meta name="title" content="Frogit" />
                <meta name="description" content="'Frogit' veikalā varēsi iegādāties radošus aksesuārus, lai paustu savu nostāju un padarītu kosāku ikdienu." />

                {/* // facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://frogit.lv/veikals" />
                <meta property="og:title" content="Frogit" />
                <meta property="og:description" content="'Frogit' veikalā varēsi iegādāties radošus aksesuārus, lai paustu savu nostāju un padarītu kosāku ikdienu." />
                <meta property="og:image" content="https://d2j6dbq0eux0bg.cloudfront.net/images/105723900/4406371566.jpg" />
            </Helmet>
            <Menu />

            <div id="my-search-####" style={{ margin: 20 }}></div>

            <div id="my-categories-####"></div>
            <div id="my-store-105723900" ref={storeDiv}></div>

        </>

    );
}