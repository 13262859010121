import React from "react"
import Contacts from "./contacts"
import Home from "./home"
import Offers from "./offers"
import Projects from "./projects"
import Team from "./team"
import Menu from "./menu"

export default function Homescreen(){
    return(
        <>
         <Menu />
        <Home/>
        <Offers/>
        <Projects/>
        <Team/>
        <Contacts/>
        </>
    )
}