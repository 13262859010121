import React, {useState, useEffect, } from "react"
import { RxAvatar } from "react-icons/rx#RxAvatar"
import { Link } from "react-router-dom";
import Header from "./header";
import Recents from "./recents";
import axios from "axios";

export default function Content() {
    window.scrollTo(0, 0)
    const [data, setData] = useState([])

    useEffect(() => {
        getBlogposts()
    }, [])

    const getBlogposts = () => {
        axios.get("https://minidigitalserver.frogit.lv/posts/frogit/page/49/1")
            .then(response => setData(response.data))
    }
    return (
        <>
            <Header />
            <div id="content" className="content">
                <div className="posts">

                    {data.toReversed().map(x =>
                        <div className="post" key={x.id}>
                            < img title={x.text} src={x.file ? "https://minidigitalserver.frogit.lv" + x.file : ""} alt={x.text} width="600px" height="400px"/>
                            <span><RxAvatar /><p>{x.author.split(" ", 1)}</p></span>
                            <h1>{x.title}</h1>
                            <p>{x.text.split("",350).join("") + "..."}</p>
                            <Link to={`/blogs/${x.id}`} state={{ data: x }} className="link"><button>LASĪT</button></Link>
                        </div>
                    )}
                </div>
         <Recents />

            </div>
        </>
    )
}