import React, { useState } from "react";
import axios from 'axios';

export default function Email() {
    const [email, setEmail] = useState("")
    const [alertcomp, setAlert] = useState(false)


    const emailChange = (e) => {
        setEmail(e.target.value)
    }

    const sendemail=()=>{
        axios.post('https://mail.frogit.lv/reg.php', {email}, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
            .then((response) => {
                if(response.data.status === "ok")
                {setEmail("") 
                setAlert(true)
            }else{console.log("error")}})
        
    }
    return (
        <>
            <div className="sib-form" >
                <h4 style={{color: "black"}}>Atstāj e-pastu un Sandra ar Tevi sazināsies&nbsp;</h4>
           
                <input  className="input-email" value={email} onChange={emailChange} type="text" id="EMAIL" name="EMAIL" autoComplete="off" placeholder="e-pasts" data-required="true" required />

            {alertcomp ? <div className="alert">Jūsu e-pasts nosūtīts. Drīz saņemsiet ziņu. Ja nesaņemat to šodien, pārbaudiet <i>spama</i> kastīti.</div> 
            : <button form="sib-form" type="submit" onClick={sendemail} >
                NOSŪTĪT
            </button>}
            </div>
        </>
    )
}