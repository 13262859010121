import React from "react"
import shape from "../components/assets/shape.png"
import shape1 from "../components/assets/shape1.png"
import hero from "../components/assets/herogreen.jpeg"


export default function NotFound() {

    return (
        <div className="column">
            <section id="sākums" className="home ">
                <div className="shape">
                    <img src={shape} alt="shape" title="Vilnis"/>
                </div>
                <div className="shape1" >
                    <img src={shape1} alt="shape" title="Vilnis"/>
                </div>
                <div className="heading">
                    <h1 style={{ fontSize: "120px", textAlign: "center" }}>404</h1>
                    <p style={{ fontSize: "30px", textAlign: "center" }}>Šāda lapa nav atrasta </p>
                
                </div>
                <div className="hero">
                    <img src={hero} alt="hero" width="450" height="auto" title="Hero attēls"/>
                </div>
            </section >
        </div>
    )
}