import React from "react"
import { FaReact, FaLaravel } from "react-icons/fa"
import { BiLogoPhp } from "react-icons/bi#BiLogoPhp"
import { DiNodejs } from "react-icons/di#DiNodejs"
import {SiMysql} from "react-icons/si#SiMysql"

export default function Technologies() {
    return (
        <div className="technologies">
            <span><FaReact size={50} /> React</span>
            <span><BiLogoPhp size={50} /></span>
            <span><DiNodejs size={60} /></span>
            <span><FaLaravel size={40} />Laravel</span>
            <span><SiMysql size={60}/></span>
        </div>
    )
}