import React, { useEffect, useState } from "react"
import { RxAvatar } from "react-icons/rx#RxAvatar"
import Header from "./header";
import Recents from "./recents";
import { useParams } from 'react-router-dom';
import NotFound from "../notfound";
import axios from "axios";

export default function Post() {

    window.scrollTo(0, 0)

    const params = useParams()
    const id = parseInt(params.id)

    const [data, setData] = useState([])
    
    useEffect(() => {
        getBlogposts()
    }, [id])

    const getBlogposts = () => {
        axios.get("https://minidigitalserver.frogit.lv/posts/frogit/page/49/1")
            .then(response => setData(response.data.filter((x) => x.id === id)[0]))
    }
    let image = ""
    if(data.file){
    image = "https://minidigitalserver.frogit.lv" + data?.file
    }

    if (!data) {
        return <NotFound />
    }
    return (
        <>
            <Header text={data?.title} />
            <div id="content" className="content">
                <div className="posts">
                    <div className="post" key={data?.id}>
                        <img src={image} alt={data?.text} title={data?.text} />
                        <span><RxAvatar /><p>{data?.author?.split(" ", 1)}</p></span>
                        <p dangerouslySetInnerHTML={{ __html: data?.preText }}></p>
                    </div>
                </div>
                <Recents />
            </div>
        </>
    )
}
